export const valuesExerciseValues = [
  {
    title: "Adventure",
    active: false,
    description:
      "seeks out and/or participates in exciting events that involve uncertainty",
  },
  {
    title: "Autonomy",
    active: false,
    description:
      "places importance on freedom, independence and individual discretion",
  },
  {
    title: "Challenges",
    active: false,
    description:
      "things or situations that test a person's thoughts or abilities",
  },
  {
    title: "Change",
    active: false,
    description: "a shift from one state, stage or phase to another",
  },
  {
    title: "Community",
    active: false,
    description:
      "a group of people with a common background or characteristic or with shared interests",
  },
  {
    title: "Competence",
    active: false,
    description: "ability to solve problems, demonstrates mastery",
  },
  {
    title: "Competition",
    active: false,
    description:
      "comparison of self, team or organization against oneself or another",
  },
  {
    title: "Cooperation",
    active: false,
    description:
      "placing importance on working together to achieve results; go along with a role",
  },
  {
    title: "Creativity",
    active: false,
    description:
      "placing importance on imagination, inspiration and inventiveness",
  },
  {
    title: "Decisiveness",
    active: false,
    description:
      "the ability to make decisions firmly, clearly, conclusively and in a timely manner",
  },
  {
    title: "Diversity",
    active: false,
    description: "appreciates and respects individual differences",
  },
  {
    title: "Ecology / Environment",
    active: false,
    description: "awareness of natural resources",
  },
  {
    title: "Education",
    active: false,
    description: "placing importance on learning and education",
  },
  {
    title: "Ethics",
    description: "valuing moral standards",
    active: false,
  },
  {
    title: "Excellence",
    description: "pursuit of the highest level of optimal performance",
    active: false,
  },
  {
    title: "Excitement",
    description:
      "things that engage attention and/or emotion, in a lively, stimulating and compelling way",
    active: false,
  },
  {
    title: "Fairness",
    description: "placing importance in justice, decency and equality",
    active: false,
  },
  {
    title: "Fame",
    description: "recognition by others, desire to be well-known",
    active: false,
  },
  {
    title: "Family",
    description: "connection to immediate or expended relations",
    active: false,
  },
  {
    title: "Flexibility",
    description: "the ability to adapt to environments, situations or changes",
    active: false,
  },
  {
    title: "Freedom",
    description: "without obligation",
    active: false,
  },
  {
    title: "Friendship",
    description:
      "strong ties with family, friends, co-workers or members of a certain community",
    active: false,
  },
  {
    title: "Happiness",
    description: "feelings of contentment, satisfaction and/or fulfillment",
    active: false,
  },
  {
    title: "Health",
    description: "placing importance on physical and emotional well-being",
    active: false,
  },
  {
    title: "Helping Others",
    description: "placing importance on assisting other people",
    active: false,
  },
  {
    title: "Honesty",
    description:
      "telling the truth, does not engage in deception, is forthright and candid",
    active: false,
  },
  {
    title: "Independence",
    description: "ability to be self-directed in one's thoughts and actions",
    active: false,
  },
  {
    title: "Integrity",
    description:
      "firm adherence to a moral code and/or set of values, walking the talk",
    active: false,
  },
  {
    title: "Leadership",
    description:
      "the ability to create relationships in order to complete a stated goal or mission",
    active: false,
  },
  {
    title: "Loyalty",
    description: "allegiances to people, organizations, ideals, causes, etc.",
    active: false,
  },
  {
    title: "Meaningful Work",
    description:
      "doing and providing work that has a purpose and/or signifigance",
    active: false,
  },
  {
    title: "Money",
    description: "financial assets, the accumulation of wealth",
    active: false,
  },
  {
    title: "Order",
    description: "adherence to a system, protocol or code",
    active: false,
  },
  {
    title: "Philanthropy",
    description: "giving of monetary gifts in order to benefit others",
    active: false,
  },
  {
    title: "Play",
    description: "imagination, spontaneity; the ability to be amused",
    active: false,
  },
  {
    title: "Pleasure",
    description: "enjoyment, delight, satisfaction or fulfillment",
    active: false,
  },
  {
    title: "Power",
    description: "strength, superiority, dominance and/or authority",
    active: false,
  },
  {
    title: "Privacy",
    description: "the need for solitude or separateness",
    active: false,
  },
  {
    title: "Recognition",
    description: "acknowledgment and/or validation",
    active: false,
  },
  {
    title: "Relationships",
    description: "connections between and among people",
    active: false,
  },
  {
    title: "Religion",
    description: "a specific system of belief or worship",
    active: false,
  },
  {
    title: "Safety",
    description:
      "a concern for the security and well-being of one's self and/or others",
    active: false,
  },
  {
    title: "Security",
    description:
      "the feeling of being protected or safeguarded from danger, a sense of comfort",
    active: false,
  },
  {
    title: "Service",
    description: "placing importance on serving others with quality and value",
    active: false,
  },
  {
    title: "Spirituality",
    description:
      "appreciating the need to understand one's inner self and its relationship with the world",
    active: false,
  },
  {
    title: "Stability",
    description:
      "taking on day-to-day activities in a calm and consistent manner",
    active: false,
  },
  {
    title: "Status",
    description:
      "placing importance on the rank or position of something in a relationship or group",
    active: false,
  },
  {
    title: "Wealth",
    description: "richness in terms of assets or money",
    active: false,
  },
  {
    title: "Work",
    description: "employment, occupation, or fulfillment of a task or duty",
    active: false,
  },
];