import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../api";



const cloudinaryImageUpload = async (file, source = 'text_editor') => {
    let cloudinaryApi
    let endpoint
    if (source === 'text_editor') {
        cloudinaryApi = new ApiRequest(
            API_ROUTES.CLOUDINARY,
            API_AUTH_TYPES.USER
        )
        endpoint = "upload_file"
    }
    if (source === 'parabot') {
        cloudinaryApi = new ApiRequest(
            API_ROUTES.CLOUDINARY,
            API_AUTH_TYPES.ADVISOR
        )
        endpoint = "upload_file_parabot"
    }

    try {
        let formData = new FormData();
        formData.append("image", file);
        const url = await cloudinaryApi.post({
            endpoint,
            body: formData,
        });

        return url;
    } catch (error) {
        console.error("Error:", error);
        throw new Error("Upload failed");
    }
}

export default cloudinaryImageUpload;