
import React from "react";
import { useState } from "react";
import SideNavBar from "../../components/nav/side-navbar";
import { classNames } from "../../helpers/classNames";
import useArray from "./../../hooks/useArray";
import { patchReq } from "./../../apis/internalApi";
import { API } from "./../../constants/index";
import { useSelector } from "react-redux";
import {
  AlertError,
  AlertCaution,
  AlertSuccess,
} from "./../../components/alerts";
import { valuesExerciseValues } from "../../helpers/values";
import useNav from "../../hooks/useNav";
const valueStyles = {
  true: "bg-green-200 text-green-800",
  false: "bg-gray-100 text-gray-800",
};
const Values = () => {
  const nav = useNav();

  const valuesArr = useArray(valuesExerciseValues);
  const [valueCount, setValueCount] = useState(0);
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [caution, setCaution] = useState("");
  const [success, setSuccess] = useState("");
  const [hideUnselected, sethideUnselected] = useState(false);
  const accessToken = useSelector((state) => state.user.accessToken);

  const postValues = async () => {
    const selectedValues = valuesArr.array
      .map((value) => {
        if (value.active) return value;

        return null;
      })
      .filter((value) => value !== null);
    selectedValues.forEach((value) => {});

    try {
      const response = patchReq(API.USERS, "patch_user_values", accessToken, {
        values: selectedValues,
      });
      return response.status;
    } catch (error) {
      setError(error.message);
    }
  };

  const handleClick = (index = 0, newState) => {
    valuesArr.update(index, {
      title: valuesArr.array[index].title,
      active: newState,
      description: valuesArr.array[index].description,
    });
    if (newState === true) {
      setValueCount(valueCount + 1);
    }
    if (newState === false) {
      setValueCount(valueCount - 1);
    }
  };

  const handleSubmit = async () => {
    setError("");
    setCaution("");
    setSuccess("");
    if (step === 0) {
      setStep(1);
      return;
    }
    if (valueCount < 5) {
      setCaution("Please select at least 5 values.");
      return;
    }
    if (valueCount > 15) {
      if (step === 2) {
        setCaution(
          "You must narrow down your values selection to 15 before you can continue"
        );
      } else {
        setStep(2);
        setSuccess("Awesome job! Now narrow down your selections to just 15.");
      }
    }
    if (15 >= valueCount && valueCount > 10) {
      if (step === 3) {
        setCaution(
          "You must narrow down your values selection to 10 before you can continue"
        );
      } else {
        setSuccess("Awesome job! Now narrow down your selections to just 10.");
        setStep(3);
      }
    }
    if (10 >= valueCount && valueCount > 5) {
      if (step === 4) {
        setCaution(
          "You must narrow down your values selection to 5 before you can continue"
        );
      } else {
        setSuccess("Awesome job! Now narrow down your selections to just 5.");
        setStep(4);
      }
    }
    if (5 === valueCount) {
      const response = await postValues();
      setStep(5);
    }
    if (step === 5) {
      nav("/dashboard");
    }
  };
  return (
    <div>
      <SideNavBar>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
          <div className="text-center">
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Values Exercise
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-700">
              {" "}
              {step === 1 &&
                "Discovering the things that we truly value can be a huge help in planning our finances."}
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
              {step === 1 && " Please select all values that apply to you"}
              {step === 2 &&
                " Please narrow you selection down to 15 values"}{" "}
              {step === 3 && "Awesome! Now try to get them down to just 10."}{" "}
              {step === 4 && "Finally, narrow your selections down to just 5."}{" "}
              {step === 5 &&
                "Whoot! You did it! We will keep a record of your values so that we can use them as a guide post as we work through your financial journey. It can also be useful to repeat this exercise as well from time to time."}{" "}
            </p>
          </div>{" "}
        </div>
        <div className="flex flex-col items-center space-y-10 justify-center mb-10">
          {" "}
          <button
            onClick={handleSubmit}
            className="btn btn-wide bg-c-orange-abundo text-white py-5 px-10"
          >
            {step === 5 ? "Back to Dashboard" : "Save & Continue"}
          </button>
          {caution && <AlertCaution dismissBtn={true}>{caution}</AlertCaution>}
          {error && <AlertError dismissBtn={true}>{error}</AlertError>}
          {success && <AlertSuccess dismissBtn={true}>{success}</AlertSuccess>}
        </div>
        {step > 0 && (
          <h3 className="mb-10 text-center">Selected Values: {valueCount}</h3>
        )}
        {step > 1 && (
          <div className="flex justify-end items-center mb-5">
            {" "}
            <input
              type="checkbox"
              name="hide-selected"
              id="hide-selected"
              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              value={hideUnselected}
              onChange={() => {
                sethideUnselected(!hideUnselected);
              }}
            />
            <label
              htmlFor="hide-selected"
              className="font-medium text-gray-700 pl-3"
            >
              Hide Unselected
            </label>
          </div>
        )}
        <div
          role="list"
          className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 lg:grid-cols-4 sm:gap-x-6 xl:gap-x-8"
        >
          {step > 0 &&
            valuesArr.array.map((value, index) => (
              <button
                key={index}
                onClick={() => {
                  handleClick(index, !value.active);
                }}
                className={classNames(
                  value.active ? 'bg-green-100 text-green-800' : 'bg-white text-gray-800',
                  `${
                    hideUnselected && !value.active ? "hidden" : ""
                  } flex flex-col justify-center items-center border-2  py-6 px-10  shadow-md rounded-2xl hover:shadow-xl transition duration-300  text-center`
                )}
              >
                {" "}
                <p className="text-xl sm:text-2xl font-semibold mb-1">{value.title}</p>
                <p>{value.description}</p>
              </button>
            ))}
        </div>{" "}
        <p className="max-w-xl mt-5  text-gray-700">
          Exercise and values can be originally found on{" "}
          <span>
            <a
              href="https://www.think2perform.com/our-approach/values/new"
              target="_blank"
              className="text-c-hyperlink underline"
              rel="noopener noreferrer"
            >
              think2perform.com
            </a>
          </span>
        </p>
      </SideNavBar>
    </div>
  );
};

export default Values;
