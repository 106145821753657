import React from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { classNames } from '../../helpers/classNames'

const SmallLoadingSpinner = ({textColor='text-gray-600',className ='h-5 w-5 animate-spin'}) => {
  return (
      <AiOutlineLoading3Quarters className={classNames(textColor,className) }/>
  )
}

export default SmallLoadingSpinner