import React from "react";
import MarkdownNotes from "../../../../../../components/content/MarkdownNotes";
import RichTextDisplay from "../../../../../../components/textEditor/RichTextDisplay";
import { classNames } from "../../../../../../helpers/classNames";

const DisplayTaskRichText = ({ task, isClientPortalTask,query }) => {
  const taskClassName = isClientPortalTask ? "client-portal-task" : "markdown-notes";
  return (
    <div className={classNames((task.status === 'completed' && isClientPortalTask) ? 'line-through text-gray-400' :'text-gray-800',"text-left w-full",task.status !== 'highlighted' && 'text-gray-400')}>
        {task?.content && (
          <div>
            <RichTextDisplay content={task.content} thisClassName={taskClassName} query={query} />
          </div>
        )}
        {task?.description?.delta && (
          <div>
            <RichTextDisplay
              content={{ ops: task.description.delta }}
            thisClassName={taskClassName}
            query={query}
            />
          </div>
        )}

        {task?.description?.blocks && (
          <>
            <MarkdownNotes noteRaw={task.description} />
          </>
        )}
    </div>
  );
};

export default DisplayTaskRichText;
