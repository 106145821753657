import { UserCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useSelector } from "react-redux";
import { patchReq, postReq } from "../../../../../../../apis/internalApi";
import {
  countries,
  usStates,
} from "../../../../../../../components/option-lists/geography";
import { longUid } from "../../../../../../../helpers/id-generators";
import useArray from "../../../../../../../hooks/useArray";
import { API } from "../../../../../../../constants/index";
import { dateFormatterYYYYMMDD } from '../../../../../../../helpers/formatters';
import Toggle from "../../../../../../../components/toggle";

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const updateUser = async (accessToken, userId, body) => {
  try {
    const response = await patchReq(
      API.USERS,
      `users_update_auth_advisor/${userId}`,
      accessToken,
      body
    );
    return response;
  } catch (error) {}
};

const newUser = async (accessToken, body, householdId) => {
  const generatedId = longUid();
  body.user_id = generatedId;
  try {
    const response = await postReq(
      API.USERS,
      "users_post_auth_advisor",
      accessToken,
      body
    );
   
  } catch (error) {}
};

export default function EditUser({ person, type, householdId }) {
  const accessToken = useSelector((state) => state.user.accessToken);
  const [firstName, setFirstName] = useState(person.first_name || "");
  const [lastName, setLastName] = useState(person.last_name || "");
  const [primaryEmail, setPrimaryEmail] = useState(person.email || "");
const [secondaryEmail,setSecondaryEmail] = useState(person.secondary_email || "")
  const [maritalStatus, setMaritalStatus] = useState(
    person.marital_status || ""
  );
  const [jobTitle, setJobTitle] = useState(person.job_title || "");
  const [company, setCompany] = useState(person.company ? person.company : "");
  const [phoneNumber, setPhoneNumber] = useState(
    person.phone_number ? person.phone_number : ""
  );
  const [streetAddress, setStreetAddress] = useState(
    person.street_address ? person.street_address : ""
  );
  const [streetAddress2, setStreetAddress2] = useState(
    person.stress_address_2 ? person.stress_address_2 : ""
  );
  const [city, setCity] = useState(person.city ? person.city : "");
  const [state, setState] = useState(person.state ? person.state : "");
  const [zip, setZip] = useState(person.zip ? person.zip : "");
  const [country, setCountry] = useState(person.country ? person.country : "");
  const [gender, setGender] = useState(person.gender ? person.gender : "");
  const [dob, setDob] = useState(person.dob ? dateFormatterYYYYMMDD(new Date(person.dob)) : null);
  const [taxStatus, setTaxStatus] = useState(person.tax_status);
  const [isDeceased, setIsDeceased] = useState(person.is_deceased);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      first_name: firstName,
      last_name: lastName,
      primary_email: primaryEmail,
      secondary_email: secondaryEmail,
      marital_status: maritalStatus,
      tax_status: taxStatus,
      job_title: jobTitle,
      company,
      phone_number: phoneNumber,
      street_address: streetAddress,
      street_address_2: streetAddress2,
      city,
      state,
      zip,
      country,
      gender,
      dob,
      is_deceased:isDeceased,
      tax_status:taxStatus,
      household_id:householdId
    };
    if (type === "edit user") {
      const update = await updateUser(accessToken, person.user_id, body);
    }
    if (type === "new user") {
      const response = await newUser(accessToken, body,householdId);
    }
    window.location.reload();
  };
  return (
    <form
      className="space-y-8 divide-y divide-gray-200"
      onSubmit={(e) => handleSubmit(e)}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="pt-8">
          <div>
            {" "}
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Personal Information
            </h3>
            <p className="mt-1 text-sm text-gray-500">Personal Information</p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            {/* First Name */}
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                First Name*
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  name="first-name"
                  id="first-name"
                  autoComplete="firstname"
                  className="flex-1 focus:ring-gray-500 focus:border-gray-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                  required
                />
              </div>
            </div>
            {/* End First Name */}
            {/* Last Name */}
            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name*
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  autoComplete="lastname"
                  className="flex-1 focus:ring-gray-500 focus:border-gray-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                  required
                />
              </div>
            </div>
            {/* End Last Name */}

            {/* Phone Number */}
            <div className="sm:col-span-3">
              <label
                htmlFor="phone-number"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="tel"
                  name="phone-number"
                  id="phone-number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  autoComplete="phone"
                  className="flex-1 focus:ring-gray-500 focus:border-gray-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                />
              </div>
            </div>
            {/* End Phone Number*/}
            {/* Phone Number */}
            <div className="sm:col-span-3">
              <label
                htmlFor="phone-number"
                className="block text-sm font-medium text-gray-700"
              >
                Date of Birth*
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="date"
                  name="dob"
                  id="dob"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  autoComplete="bday"
                  className="flex-1 focus:ring-gray-500 focus:border-gray-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                />
              </div>
            </div>
            {/* End Phone Number*/}
            {/* Primary Email */}
            <div className="sm:col-span-3">
              <label
                htmlFor="primary-email"
                className="block text-sm font-medium text-gray-700"
              >
                Primary Email*
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="email"
                  name="primary-email"
                  id="primary-email"
                  value={primaryEmail}
                  onChange={(e) => setPrimaryEmail(e.target.value)}
                  autoComplete="email"
                  required
                  className="flex-1 focus:ring-gray-500 focus:border-gray-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                />
              </div>
            </div>
            {/* End Primary Email*/}
            {/* Secondary Email */}
            <div className="sm:col-span-3">
              <label
                htmlFor="secondary-email"
                className="block text-sm font-medium text-gray-700"
              >
                Secondary Email
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="email"
                  name="secondary-email"
                  id="secondary-email"
                  value={secondaryEmail}
                  onChange={(e) => setSecondaryEmail(e.target.value)}
                  autoComplete="lastname"
                  className="flex-1 focus:ring-gray-500 focus:border-gray-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                />
              </div>
            </div>
            {/* End Secondary Email*/}
            {/* Marital Status */}
            <div className="sm:col-span-2">
              <label
                htmlFor="marital-status"
                className="block text-sm font-medium text-gray-700"
              >
                Marital Status
              </label>
              <div className="mt-1">
                <select
                  id="marital-status"
                  name="marital-status"
                  autoComplete="marital-status"
                  value={maritalStatus}
                  onChange={(e) => {
                    setMaritalStatus(e.target.value);
                  }}
                  className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="" key="pleaseSelect" hidden>
                    Please Select
                  </option>
                  <option>Single</option>
                  <option>Married</option>
                  <option>Widowed</option>
                  <option>Divorced</option>{" "}
                  <option>Separated</option>{" "}
                  <option>Domestic Partnership</option>
                </select>
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="marital-status"
                className="block text-sm font-medium text-gray-700"
              >
           Tax Status
              </label>
              <div className="mt-1">
                <select
                  id="marital-status"
                  name="marital-status"
                  autoComplete="marital-status"
                  value={taxStatus}
                  onChange={(e) => {
                    setTaxStatus(e.target.value);
                  }}
                  className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="" key="pleaseSelect" hidden>
                    Please Select
                  </option>
                  <option>Single</option>
                  <option>Married filing jointly</option>
                  <option>Married filing separately</option>
                  <option>Head of household</option>{" "}
                  <option>Qualifying widow(er) with dependent child</option>{" "}
                </select>
              </div>
            </div>
            {/* End Marital Status*/}
            {/* Marital Status */}
            <div className="sm:col-span-4">
              <label
                htmlFor="gender"
                className="block text-sm font-medium text-gray-700"
              >
                Pronouns
              </label>
              <div className="mt-1">
                <select
                  id="gender"
                  name="gender"
                  autoComplete="gender"
                  value={gender}
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                  className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                  <option value="" key="pleaseSelect" hidden>
                    Please Select
                  </option>
                  <option>He/Him</option>
                  <option>She/Her</option>
                  <option>They/Them</option>
                  <option>Prefer not to respond</option>
                </select>
              </div>
            </div>
            {/* End Marital Status*/}
            {/* Job title */}
            <div className="sm:col-span-3">
              <label
                htmlFor="job-title"
                className="block text-sm font-medium text-gray-700"
              >
                Job Title
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  name="job-title"
                  id="job-title"
                  autoComplete="job-title"
                  className="flex-1 focus:ring-gray-500 focus:border-gray-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                />
              </div>
            </div>
            {/* End Job Title */}
            {/* Job title */}
            <div className="sm:col-span-3">
              <label
                htmlFor="company"
                className="block text-sm font-medium text-gray-700"
              >
                Company
              </label>
              <div className="mt-1 flex rounded-md shadow-sm">
                <input
                  type="text"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  name="company"
                  id="company"
                  autoComplete="company"
                  className="flex-1 focus:ring-gray-500 focus:border-gray-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="job-title"
                className="block text-sm font-medium text-gray-700"
              >
                Is Deceased
              </label>
              <div className="mt-2 flex rounded-md shadow-sm">
               <Toggle enabled={isDeceased} setEnabled={setIsDeceased} color="red" />
              </div>
            </div>
          </div>{" "}
        </div>
        <div>
          {/* End Job Title */}
          <div className="pt-8">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Address{" "}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Use a permanent address where you can receive mail.
              </p>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              {/* Country */}
              <div className="sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Country
                </label>
                <div className="mt-1">
                  <select
                    id="country"
                    name="country"
                    autoComplete="country-name"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  >
                    {countries}
                  </select>
                </div>
              </div>
              {/* End Country */}
              {/* Street Address */}
              <div className="sm:col-span-6">
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Street address
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="street-address"
                    id="street-address"
                    value={streetAddress}
                    onChange={(e) => setStreetAddress(e.target.value)}
                    autoComplete="address-line1"
                    className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              {/* End Street Address */}
              {/* Street Address 2*/}
              <div className="sm:col-span-6">
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Street address 2
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="street-address-2"
                    id="street-address-2"
                    value={streetAddress2}
                    onChange={(e) => setStreetAddress2(e.target.value)}
                    autoComplete="address-line2"
                    className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              {/* End Street Address */}
              <div className="sm:col-span-2">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  City*
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    autoComplete="city"
                    className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="state"
                  className="block text-sm font-medium text-gray-700"
                >
                  State / Province*
                </label>
                <div className="mt-1">
                  <select
                    id="state"
                    name="state"
                    autoComplete="state-name"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  >
                    {usStates}
                  </select>
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium text-gray-700"
                >
                  ZIP / Postal code
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    autoComplete="postal-code"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    className="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-5">
        <div className="flex justify-end">
       
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            Save Changes
          </button>
        </div>
      </div>
    </form>
  );
}
