import React, { useMemo } from "react";
import { useState } from "react";
import { patchReq } from "../../../../apis/internalApi";
import SmartTableEditCell from "../SmartTable___EditCell";
import { currencyFormatter } from "../../../../helpers/formatters";
import { classNames } from "../../../../helpers/classNames";

const SmartTableNumber = ({ rowId, initValue, accessToken, table, colId, editable ,format,editId}) => {
  const [value, setValue] = useState(initValue || null);
  const [showEdit, setShowEdit] = useState(false);
  //handling next thing changes
  const submitChanges = async () => {
    try {
      const results = await patchReq(table, "smart_table_update", accessToken, {
        value,
        row_id: editId,
        col_id: colId,
      });
    } catch (error) {
    }
  };
  const formattedValue = useMemo(() => {
    if (value === null) return null;
    if (format === "currency") {
      return currencyFormatter.format(value);
    }
    return value;
  }, [value,format]);
  return (
    <div className="smart-table-cell">
      {editable ? (
        <SmartTableEditCell
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          handleSubmitEdit={submitChanges}
          defaultComponent={<p className={classNames(format !== "currency" ? "text-left" : 'text-right')}>{formattedValue}</p>}
          editComponent={
            <div className="">
              <input
                type="number"
                name={`number_${colId}`}
                id={`number_${colId}`}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="shadow-sm   focus:ring-0 focus:border-blue-500 border-blue-500 block w-full sm:text-sm rounded-md"
              />
            </div>
          }
        />
      ) : (
        <p className={classNames(format !== "currency" ? "text-left" : 'text-right')}>{formattedValue}</p>
      )}
    </div>
  );
};

export default SmartTableNumber;
