import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../api';
import { useEffect } from 'react';



const useAdvisorPreloadedData = (parentDataLoaded) => {
    // Query for email threads
    const bunmailApi = new ApiRequest(API_ROUTES.BUNMAIL, API_AUTH_TYPES.ADVISOR);
    const queryClient = useQueryClient();
    useQuery({
        queryFn:
            bunmailApi.getFn({
                endpoint: 'index_threads',
                params: ['inbox', '1'],
            }),
        queryKey: ['index_threads', 'inbox', '1'],
        enabled: parentDataLoaded,  // Only run when parent data is loaded
        // staleTime: 300000,  // Adjust this option based on your needs
        refetchOnWindowFocus: false,  // Prevent refetch on window focus
    });


    // const hardRefreshMutation = useMutation(() =>
    //     bunmailApi.patch({
    //         endpoint: 'hard_refresh',
    //     }),

    //     {

    //         onSuccess: () => {
    //             queryClient.invalidateQueries(["index_threads"])
    //             queryClient.invalidateQueries(["labels"])
    //         }
    //     });

    // useEffect(() => {
    //     hardRefreshMutation.mutate()
    // }, [])


};



export default useAdvisorPreloadedData;
