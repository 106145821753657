import React from 'react'
import { useAppContext } from '../../context/app-context'
import SuccessToast from './SuccessToast'
import ErrorToast from './ErrorToast'

const Toasts = () => {
     const { toasts } = useAppContext()
    console.log('toasts',toasts)
    if (!toasts?.array?.length) return null
    return (
        <ul className='space-y-4 absolute top-10 right-20 w-64 z-50'>{
            toasts.array.map(toast => (
                <li key={toast.id}>
                    {toast.type === 'success' && <SuccessToast toast={toast} />}
                    {toast.type === 'error' && <ErrorToast toast={toast} />}
                </li>
            )
            )
        }</ul>
    )
}

export default Toasts

