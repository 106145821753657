import React, { useMemo, useState } from "react";
import ApiRequest from "./../../../../../api/ApiRequest";
import { API_AUTH_TYPES, API_ROUTES } from "./../../../../../api/apiConstants";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import DoubleClickEdit from "../../../../../components/DoubleClickEdit";
import { PencilAltIcon, PencilIcon } from '@heroicons/react/outline';

const Advisor = () => {
  const { household_id } = useParams();
  //fetch advisors from db
  const usersApi = new ApiRequest(API_ROUTES.USERS, API_AUTH_TYPES.ADVISOR);
  const [showEdit, setShowEdit] = useState(false);

  const {
    data: advisors,
    isLoading,
    isError,
  } = useQuery({
    queryFn: usersApi.getFn({ endpoint: "index_advisors" }),
    queryKey: "advisors",
  });
  const householdsApi = new ApiRequest(
    API_ROUTES.HOUSEHOLDS,
    API_AUTH_TYPES.ADVISOR
  );
  const {
    data: household,
    isLoading: householdsLoading,
    isError: householdsError,
  } = useQuery({
    queryFn: householdsApi.getFn({
      endpoint: "details_households",
      params: [household_id],
    }),
    queryKey: "households-advisor",
  });
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (data) => householdsApi.patch({ endpoint: "update_advisor", body: { advisor_id: data, household_id } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("households-advisor");
      },
    }
  );

  const selectedAdvisor = useMemo(
    () =>
      advisors?.find((advisor) => advisor?.user_id === household?.advisor_id),
    [advisors, household?.advisor_id]
  );
  if (isLoading || householdsLoading) return <p>Loading...</p>;

  return (
    <div className="flex space-x-2 justify-between   "><p className="text-base text-gray-800">Advisor </p>
      <DoubleClickEdit
      showEdit={showEdit}
      setShowEdit={setShowEdit}
      handleSubmitEdit={() => { }}
      defaultComponent={

        <button className="text-gray-800 flex items-center text-base space-x-1 font-semibold" onClick={() => setShowEdit(!showEdit)}>
          <p>
            {selectedAdvisor.first_name} {selectedAdvisor.last_name} 
          </p>
       
        </button>
      }
      editComponent={
        
          <select
            name="advisor-select"
            id="advisor-select"
            className="input-primary"
            value={household.advisor_id}
            onChange={(e) => {
              mutate(e.target.value);
            }}
          >
            {advisors.map((advisor) => (
              <option key={advisor.user_id} value={advisor.user_id}>
                {advisor.first_name} {advisor.last_name}
              </option>
            ))}
          </select>
      }
    />

    </div>

  );
};

export default Advisor;
