import { useMutation, useQueryClient } from "react-query";
import ItemMenu from "../../../../../../components/menus/ItemMenu";
import DueDate from "./DueDate";
import ApiRequest from "../../../../../../api/ApiRequest";
import { API_AUTH_TYPES, API_ROUTES } from "../../../../../../api/apiConstants";
import { ArchiveIcon, CalendarIcon, StarIcon, TemplateIcon, TrashIcon } from "@heroicons/react/outline";
import TaskContent from "./TaskContent";
import { DateTime } from "luxon";
import DisplayTaskRichText from "./DisplayTaskRichText";
import { classNames } from './../../../../../../components/misc/styling';
import { dateFormatter } from "../../../../../../helpers/formatters";
import DraggableItem from "../../../../../../components/drag-drop/DraggableItem";
import DropTarget from "../../../../../../components/drag-drop/DropTarget";
import { MdOutlineDragIndicator } from "react-icons/md";
import ConfettiBox from "../../../../../../components/animations/ConfettiBox";
import AlertError from "../../../../../../components/alerts/AlertError";
import { StarIcon as StarIconSolid } from "@heroicons/react/solid";
import { useState } from "react";
import { STATUS_OPTIONS } from "../../../../../../constants";
import RepeatFrequency from "./RepeatFrequency";
import RepeatUntil from "./RepeatUntil";
import VisibleOn from "./VisibleOn";
import isVisibleNowFunction from "./isVisibleNowFunction";
const Task = ({
  task,
  tasksListQueryKey,
  chaptersListQueryKey,
  isClientPortalTask = false,
  isMeetingMode,
  completedTasksListQueryKey,
  queryKeys
}) => {
  //hooks
  //state
  //functions
  const tasksApi = new ApiRequest(
    API_ROUTES.CLIENT_TASKS,
    isClientPortalTask ? API_AUTH_TYPES.USER : API_AUTH_TYPES.ADVISOR
  );
  
  const queryClient = useQueryClient();

  const { mutate: archiveTask } = useMutation(
    (body) => tasksApi.patch({ endpoint: "archive_task", body }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(tasksListQueryKey);
        queryClient.invalidateQueries(chaptersListQueryKey)
      },
    }
  );

  const { mutate: updateDate } = useMutation(
    (body) => tasksApi.patch({ endpoint: "update_date", body }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          tasksListQueryKey
        );
      },
    }
  );

  const { mutate: updateRepeatFrequency } = useMutation(
    (body) => tasksApi.patch({ endpoint: "update_repeat_frequency", body }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          tasksListQueryKey
        );
      },
    }
  );

  const { mutate: updateVisibleOn } = useMutation(
    (body) => tasksApi.patch({ endpoint: "update_visible_on", body }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          tasksListQueryKey
        );
        queryClient.invalidateQueries(chaptersListQueryKey)
      },
    }
  );

  const { mutate: updateRepeatUntil } = useMutation(
    (body) => tasksApi.patch({ endpoint: "update_repeat_until", body }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          tasksListQueryKey
        );
      },
    }
  );
  const { mutate: updateTaskOrder } = useMutation(
    (body) => tasksApi.patch({ endpoint: "update_task_order", body }),
    {
      onSuccess: ({ old_chapter_status, new_chapter_status }) => {
        queryClient.invalidateQueries(
          tasksListQueryKey
        );
        queryClient.invalidateQueries(queryKeys[old_chapter_status]);
        queryClient.invalidateQueries(queryKeys[new_chapter_status]);
      },
    }
  );
  const { mutate: completeTask } = useMutation(
    (body) => tasksApi.patch({ endpoint: "update_task_completed", body }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(
          tasksListQueryKey
        );

        if (data.chapter_tasks_remaining === 0)
          queryClient.invalidateQueries(chaptersListQueryKey);
        queryClient.invalidateQueries(completedTasksListQueryKey);
      },
    }
  );

  const [maxHighlightsError, setMaxHighlightsError] = useState(false);
  const { mutate: toggleIsHighlighted } = useMutation(
    (body) => tasksApi.patch({ endpoint: "toggle_is_highlighted", body }),
    {
      onSuccess: (body) => {
        queryClient.invalidateQueries(
          tasksListQueryKey
        );
        queryClient.invalidateQueries(chaptersListQueryKey)
        if (body.error) {
          setMaxHighlightsError(true);
          setTimeout(() => {
            setMaxHighlightsError(false);
          }, 3000);
        }
      },
    }
  );


  const handleEditDate = async (newValue) => {
    try {
      updateDate({
        task_id: task?.task_id,
        date: newValue,
      });
    } catch (error) { }
  };

  const handleEditRepeatFrequency = async (newValue) => {
    try {
      updateRepeatFrequency({
        task_id: task?.task_id,
        new_value: newValue,
        due_date: task?.due_date,
      });
    } catch (error) { }
  };

  const handleEditRepeatUntil = async (newValue) => {
    try {
      updateRepeatUntil({
        task_id: task?.task_id,
        new_value: newValue,
      });
    } catch (error) { }
  };

  const handleEditVisibleOn = async (newValue) => {
    try {
      updateVisibleOn({
        task_id: task?.task_id,
        new_value: newValue,
      });
    } catch (error) { }
  };

  const handleArchive = async () => {
    try {
      archiveTask({
        task_id: task?.task_id,
      });
    } catch (error) { }
  };

  const handleToggleIsHighlighted = async () => {
    try {
      toggleIsHighlighted({
        task_id: task?.task_id,
        status: task?.status,
      });
    } catch (error) { }
  };


  const [displayConfetti, setDisplayConfetti] = useState(false);
  const handleCompleteTask = async () => {

    try {
      if (task?.status === STATUS_OPTIONS.TASKS.COMPLETED) {
        completeTask({
          task_id: task?.task_id,
          status: STATUS_OPTIONS.TASKS.ACTIVE,
          chapter_id: task?.chapter_id,
        });
      } else {
      

        setDisplayConfetti(true);
        setTimeout(() => {
          setDisplayConfetti(false);
          completeTask({
            task_id: task?.task_id,
            status: STATUS_OPTIONS.TASKS.COMPLETED,
            chapter_id: task?.chapter_id,
          });
        }, 3000); // Set this to the same value as the confetti duration
      }


    } catch (error) { }
  };



  const handleTaskReorder = (relativePosition, item) => {
    const droppedTaskId = item.content.props.task?.task_id
    const droppedChapterId = item.content.props.task?.chapter_id
    updateTaskOrder({
      item_id: droppedTaskId,
      dropped_over_id: task?.task_id,
      dropped_over_list_id: task?.chapter_id,
      item_list_id: droppedChapterId,
      item_type: 'task',
      household_id: task?.household_id
    });
  }


  const templateApi = new ApiRequest(API_ROUTES.FINANCIAL_STORY_TEMPLATES, API_AUTH_TYPES.ADVISOR);
  const { mutate: postTemplate } = useMutation(
    (body) => templateApi.post({ endpoint: "post_template", body: { content: task?.content ,type: 'task'} }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('task-templates')
      },
    }
  );

  const menuOptions = [
    {
      title: task?.status === STATUS_OPTIONS.TASKS.HIGHLIGHTED ? "Unhighlight" : "Highlight",
      action: handleToggleIsHighlighted,
      icon: StarIcon,

    },
    {
      title: "Create Template",
      action: postTemplate,
      icon: TemplateIcon,
    }, {
      title: "Delete",
      action: handleArchive,
      icon: TrashIcon,
    },


  ];

  const isVisibleNow = isVisibleNowFunction(task);

  return (
    <>
      {displayConfetti && <ConfettiBox />}
      <DropTarget accept="task" onDrop={(item) => handleTaskReorder('above', item)} isActiveStyling={'pt-28 drop-target'} >
        <div className="relative w-full h-1">
          <div className="h-8 absolute w-full ">

          </div>
        </div>
    
        <div className={classNames(isMeetingMode && "text-xl", " block  border border-gray-300  rounded-lg", isVisibleNow ? 'bg-white hover:gray-50' : 'bg-gray-200 bg-opacity-60')}>
        <div className="px-3 py-3 sm:px-4 relative">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <input
                type="checkbox"
                name=""
                id=""
                className="checkbox-lg"
                defaultChecked={task?.status === STATUS_OPTIONS.TASKS.COMPLETED}
                onClick={handleCompleteTask}
              />
              {isClientPortalTask ?
                task?.status === STATUS_OPTIONS.TASKS.HIGHLIGHTED && <StarIconSolid className="h-6 w-6 text-yellow-500 " /> 
             :   <button onClick={handleToggleIsHighlighted} className="p-2 ">
                {task?.status === STATUS_OPTIONS.TASKS.HIGHLIGHTED ? <StarIconSolid className="h-6 w-6 text-yellow-500 " /> : <StarIcon className="h-5 w-5  text-yellow-300 hover:text-yellow-500" />}

              </button>}
              {maxHighlightsError && <div className=" z-10"> <AlertError> You can only highlight a total of 3 tasks at a time for a household</AlertError></div>}
                <DueDateComponent isClientPortalTask={isClientPortalTask} task={task} handleEditDate={handleEditDate} />
                <VisibleOn isClientPortal={isClientPortalTask} task={task} handleSubmitEdit={handleEditVisibleOn} />
                <RepeatFrequency isClientPortal={isClientPortalTask} task={task} handleSubmitEdit={handleEditRepeatFrequency} />
                {task.repeat_frequency &&  <RepeatUntil isClientPortal={isClientPortalTask} task={task} handleSubmitEdit={handleEditRepeatUntil} />}
            </div>
            {!isClientPortalTask && (
              <div className="ml-2 flex flex-shrink-0 space-x-2 items-center ">
                <DraggableItem
                  isDraggable={!isClientPortalTask}
                  type='task'
                  item={{
                    type: 'task',
                    content: <Task
                      task={task}
                      tasksListQueryKey={tasksListQueryKey}
                      chaptersListQueryKey={chaptersListQueryKey}
                      isMeetingMode={isMeetingMode}
                      type='task'
                    />
                  }}
                >
                  <MdOutlineDragIndicator className="h-6 w-6 text-gray-500" />
                </DraggableItem>

                <ItemMenu options={menuOptions} />
              </div>
            )}
          </div>
          <div className="mt-2 pr-2 sm:space-x-10 sm:flex sm:justify-between items-end">
            {" "}
            {isClientPortalTask ? (
              
              <DisplayTaskRichText task={task} isClientPortalTask={isClientPortalTask} />
            ) : (
              <div className=" text-left text-gray-600 w-full z-10 ">
                <TaskContent task={task} listQueryKey={tasksListQueryKey}  />
              </div>
            )}
           
          </div>
        </div>
      </div>
      </DropTarget>
    </>
  );
};

function DueDateComponent({isClientPortalTask,task,handleEditDate}) {
  return (
    <>
      {isClientPortalTask ? (
        <div className="mt-2 flex items-center  text-gray-500 sm:mt-0 justify-end text-right ">
          {task?.status === STATUS_OPTIONS.TASKS.COMPLETED ?
            <div className="flex space-x-2"><p className="w-max whitespace-nowrap">Completed:</p> <p>{dateFormatter.format(new Date(task?.completed_at))}</p> </div>
            :
            <>
           
              {task?.due_date ? (
                <div className="flex space-x-2">
                  <CalendarIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <p className="mr-1">Due on</p>

                <time dateTime={task?.due_date} className="">
                  {DateTime.fromISO(task?.due_date).toLocaleString()}
                  </time>
                  </div>
              ) : (
                  <div>
                </div>
              )}
            </>}
        </div>
      ) : (
        <div className="text-right ">
          {task?.status === STATUS_OPTIONS.TASKS.COMPLETED ?
              <div className="flex space-x-2">
                <p className="w-max whitespace-nowrap">Completed:</p> <p>{dateFormatter.format(new Date(task?.completed_at))}</p> </div> :
            <DueDate
              defaultValue={task?.due_date}
              handleSubmitEdit={handleEditDate}
            />}
        </div>
      )}
    </>
  )
}
export default Task;

