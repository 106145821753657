import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../../components/layout/Layout";
import { getReq } from "../../../apis/internalApi";
import { API } from "../../../constants/index";
import useArray from "../../../hooks/useArray";
import { useEffect } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";

import FinancialStory from "./Financial-Story";


import HHName from "./HHName";
import HHReminders from "./HHReminders";
import Notes from "./Notes";
import CommandModule from "./CommandModule";
import HHEmails from "./HHEmails";
import HHMeetings from "./HHMeetings";
import useNav from "../../../hooks/useNav";
import HHSearch from "./HHSearch";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import HHMetrics from "./HHMetrics";
import { ChartBarIcon, PresentationChartBarIcon } from "@heroicons/react/outline";
import RiskTolerance from "./RiskTolerance";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../../api";
import { useQuery } from "react-query";

const fetchHousehold = async (accessToken, householdId) => {
  const response = await getReq(
    API.HOUSEHOLDS,
    `details_households_auth_advisor/${householdId}`,
    accessToken
  );
  return response.data;
};
const fetchUsers = async (accessToken, householdId) => {
  const response = await getReq(
    API.USERS,
    `users_details_household_id_auth_advisor/${householdId}`,
    accessToken
  );
  return response.data;
};





const HouseholdHome = () => {
  //
  const params = useParams();
  //state
  const contactMeta = useArray([]);
  const [householdMeta, setHouseholdMeta] = useState({});
  const [loading, setLoading] = useState(true);

  const accessToken = useSelector((state) => state.user.accessToken);
  const [module, setModule] = useState("Dashboard");

  //functions


  const createHouseholdMetaData = useCallback(async () => {
    setLoading(true);
    const fetchedHousehold = await fetchHousehold(
      accessToken,
      params.household_id
    );
    const fetchedUsers = await fetchUsers(accessToken, params.household_id);
    setHouseholdMeta(fetchedHousehold);
    contactMeta.set(fetchedUsers);


    //end fetch meetings
    setLoading(false);
  }, [accessToken, params.household_id]);

  useEffect(() => {
    createHouseholdMetaData();
  }, [createHouseholdMetaData]);

  //timers
  const timers = useArray([]);
  const fetchTimers = useCallback(async () => {
    const response = await getReq(
      API.TIMERS,
      `index_timers_household_auth_advisor/${params.household_id}`,
      accessToken
    );
    timers.set(response.data);
  }, [accessToken, params.householdId]);
  //end timers
  useEffect(() => {
    fetchTimers();
  }, [fetchTimers]);

  const youvestApi = new ApiRequest(API_ROUTES.YOUVEST,API_AUTH_TYPES.ADVISOR);

  const { data, isLoading } = useQuery({
    queryFn: youvestApi.getFn({
      endpoint: "invest_page",
      params: [params.household_id]
    }),
    queryKey: ['invest_page', params.household_id] 
  })

  return (
    <>
      {!loading ? (
        <Layout
          columns={1}
          bgColor={'bg-gray-100'}

        >
          <MainColumn householdMeta={householdMeta} module={module} setModule={setModule} contactMeta={contactMeta} timers={timers} />
        </Layout>
      ) : null}
    </>
  );
};

function MainColumn({ householdMeta, module, setModule, contactMeta, timers }) {
  const nav = useNav()
  return (
    <>

      <div className="bg-white px-5 py-1 border-b hidden lg:flex justify-between">
        <HHName householdMeta={householdMeta} />
        <div className="flex items-center justify-center space-x-10">
          <button className="btn-md btn-gray-outline  flex items-center  space-x-1.5 "
            onClick={() => nav(`/crm/youvest/${householdMeta.household_id}/My Portfolio`)}
          >
            <ChartBarIcon className='h-[18px] w-[18px] mt-0.5 ' /> <p> YouVest</p>

          </button>
          <button className="btn-md btn-gray-outline  flex items-center  space-x-1.5 "
            onClick={() => nav(`/crm/meeting-mode/${householdMeta.household_id}/financial-story`)}
          >
            <PresentationChartBarIcon className='h-[18px] w-[18px] mt-0.5 ' /> <p> Meeting Mode</p>
          </button>
          <HHSearch />

        </div>
      </div>
      <div className=" px-5 lg:px-10 mt-7 space-y-5 flex flex-col w-full items-center">



        <div className=" w-full">
          <div className="lg:hidden mb-2">
            <HHName householdMeta={householdMeta} />
          </div>

          <CommandModule module={module} setModule={setModule} />
          <div className="py-2"></div>
          {module === 'Dashboard' && <Dashboard contactMeta={contactMeta.array} householdMeta={householdMeta} />}
          {module === "Financial Story" && <FinancialStory isMeetingMode={false} />}
          {module === "Notes" && <Notes />}
          {module === "Reminders" && <HHReminders timers={timers} />}
          {module === "Emails" && <HHEmails />}
          {module === "Meetings" && <HHMeetings />}
          {module === 'Settings' && <Settings householdMeta={householdMeta} />}
          {module === 'Risk Tolerance' && <RiskTolerance />}
          {module === 'Metrics' && <HHMetrics householdMeta={householdMeta}/>}
        </div>


      </div>
    </>

  )
}



export default HouseholdHome;
