// UserSearch.js
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { Combobox } from "@headlessui/react";
import { classNames } from "../../helpers/classNames";
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from "../../api";
import { useQuery } from "react-query";
import SmallLoadingSpinner from "../loading/SmallLoadingSpinner";

const UserSearch = forwardRef(({ label = "", setSelectedUser = () => { } }, ref) => {
  const [query, setQuery] = useState("");
  const [selectedPerson, setSelectedPerson] = useState();
  const comboboxRef = useRef(null);
  const inputRef = useRef(null); // Ref for the Combobox.Input

  // Expose the `focus` method to parent components using `useImperativeHandle`
  useImperativeHandle(ref, () => ({
    focus: () => {
      console.log('Focus called on UserSearch component');
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
  }));

  const usersApi = new ApiRequest(API_ROUTES.USERS, API_AUTH_TYPES.ADVISOR);
  const { data: people, isLoading } = useQuery({
    queryFn: usersApi.getFn({ endpoint: "users_search" }),
    queryKey: ["users_search"],
  });

  useEffect(() => {
    if (!selectedPerson || !query) return;
    setSelectedUser(selectedPerson || query);
    setSelectedPerson(null);
    setQuery("");
  }, [selectedPerson]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
        if (!selectedPerson && query) {
          setSelectedUser(query);
          setSelectedPerson(null);
          setQuery("");
        }
      }
    }

    function handleKeyPress(event) {
      if (event.key === "Enter" && !selectedPerson && query) {
        setSelectedUser(query);
        setSelectedPerson(null);
        setQuery("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [query, selectedPerson]);

  if (isLoading)
    return (
      <div>
        <SmallLoadingSpinner textColor="text-gray-500" />
      </div>
    );

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) => {
        return `${person.first_name} ${person.last_name} ${person.email}`
          .toLowerCase()
          .includes(query.toLowerCase());
      });

  return people ? (
    <Combobox
      as="div"
      value={selectedPerson}
      onChange={setSelectedPerson}
      ref={comboboxRef}
    >
      {label && (
        <Combobox.Label className="block text-sm font-medium text-gray-700">
          {label}
        </Combobox.Label>
      )}
      <div className="relative">
        <Combobox.Input
          ref={inputRef} // Attach the ref to the input
          autoComplete="off"
          className="rounded-md border-none focus:ring-0 ring-0 focus:outline-none p-0 pr-10"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(person) =>
            person && `${person.first_name} ${person.last_name}`
          }
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"></Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-72 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredPeople.map((person) => (
              <Combobox.Option
                key={person.id}
                value={person}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "text-gray-900 bg-gray-200" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {person.first_name} {person.last_name}{" "}
                      <span className="text-xs text-gray-500">{person.email}</span>
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-gray-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  ) : null;
});

export default UserSearch;
